import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const ProfileCard = ({ data }) => (
  <div className="block w-full">
    <div className="container mx-auto text-2xl w-full text-palisade-yellow font-bold py-2 items-center">
      what is palisade?
    </div>
    <div className="container mx-auto text-2xl w-5/6 text-white font-normal py-2 items-center">
      <p>
        palisade is a Discord server bot developed to bulk-remove spam users.
        Simply{" "}
        <a
          className="text-2xl text-palisade-yellow font-normal py-2 items-center hover:text-white"
          title="discord"
          href="https://discord.com/api/oauth2/authorize?client_id=921581219240628254&permissions=268446774&scope=bot"
        >
          add
        </a>{" "}
        the bot to your server, move the 'Palisade' role above any roles you'd
        like managed, and then anyone with the 'Palisade Defender' role can
        command the bot to remove users with a provided name or provided prefix.
      </p>
    </div>
    <div className="container mx-auto text-2xl mt-10 w-full text-palisade-yellow font-bold py-2 items-center">
      current commands
    </div>
    <div className="container mx-auto text-2xl w-5/6 text-white font-normal py-2 items-center">
      <code className="text-palisade-yellow text-xl">
        palisade{">"}ban-members-named "John Wayne 123"
      </code>
      <p>
        Ban all members below the 'Palisade' role with an exact given name. This
        command is case sensitive. Please use quotes to capture spaces.
      </p>
      <br />
      <code className="text-palisade-yellow text-xl">
        palisade{">"}ban-members-prefixed-with "John Wayne"
      </code>
      <p>
        Ban all members below the 'Palisade' role with names starting with a
        specific prefix. Please use quotes to capture spaces.
      </p>
    </div>
    <div className="container mx-auto text-2xl mt-10 w-full text-palisade-yellow font-bold py-2 items-center">
      see it in action
    </div>
    <div className="flex mx-auto justify-center text-2xl w-full mt-4 text-palisade-yellow font-bold items-center">
      <iframe
        width="700"
        height="400"
        src="https://www.youtube.com/embed/wcOH9UI1418"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <div className="container mx-auto text-2xl mt-10 w-full text-palisade-yellow font-bold py-2 items-center">
      find the bot useful?
    </div>
    <div className="container mx-auto text-2xl w-5/6 text-white font-normal py-2 items-center">
      <p>
        Consider donating to help cover hosting and maintenance costs! Not
        necessary, but always greatly appreciated :)
      </p>
      <p className="flex mt-2 flex-wrap break-all">
        Donate via ETH: 0xF465D6F3B993a6bE3F51B98449F06Ea7432E7871
      </p>
    </div>
    <div className="block w-1/2 mx-auto mt-2 justify-end align-center items-center">
      <button
        className="block mx-auto justify-center py-3 px-4 text-xl font-semibold border border-transparent rounded-md text-palisade-grey bg-palisade-yellow hover:text-palisade-yellow hover:bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-palisade-yellow"
        type="submit"
        onClick={(e) => {
          e.preventDefault();
          window.location.href = "https://cash.app/$brokengun";
        }}
      >
        Donate via CashApp
      </button>
    </div>
  </div>
);

ProfileCard.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      plan: PropTypes.string,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      description: PropTypes.string,
      items: PropTypes.array,
    })
  ),
};

export default ProfileCard;
